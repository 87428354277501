@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Nunito+Sans:wght@300;400;700&display=swap);
:root {
    /* Color styles */
    --primary--color: #d02128;
    --least--color: #17bebb;
    --secondary: #2095ba;
    --primary--red: #d02128;
    --primary--orange: #f79824;
    --background--page--color: #f2f2f2;
    --pill--orange: #ff7c5d;
    --pill--hard--grey: #f0f0f0;
    --pill--selected--color: #ff434a;
    --stroke--color: #d5d5d5;
    --text-secondary: #9e9e9e;
    --background-component: #ffffff;
    --black-color: #1d1d1d;
    /* Text-size styles */
    --cost--detail: 12px;

    /* Effect styles */
    --box--shadow: 0px 1px 9px rgba(0, 0, 0, 0.09);
    --box--shop--shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
    --input--focus--shadow: 0px 1px 9px rgba(0, 0, 0, 0.25);
    --payment--method--focus--shadow: 0px 7px 23px rgba(247, 152, 36, 0.19);
}

body {
    background-color: var(--background--page--color);
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
}

/* Animation */
@keyframes skeletonanimation {
    0% {
        opacity: 60%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 60%;
    }
}

/* Bootstrap Component Change */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
}

.page-title {
    margin-bottom: 48px;
}

.btn {
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    border-radius: 20px;
    height: 40px;
    line-height: 20px;
    letter-spacing: 0.7px;
    vertical-align: middle;
    text-transform: uppercase;
    padding: 0px 40px;
}

a.btn {
    display: inline-flex;
    align-items: center;
}

.btn-primary {
    background-color: var(--primary--color);
    border-color: var(--primary--color);
}

.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: var(--primary--color) !important;
    border-color: var(--primary--color) !important;
}

.copy-btn {
    height: 24px;
    padding: 0 10px;
    border-radius: 12px;
    border: solid 1px #1d1d1d;
    background: 0 0;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9;
    letter-spacing: .1px;
    text-align: center;
    color: #1d1d1d;
}

.copy-btn.copied {
    border: solid 1px #17bebb;
    color: #17bebb;
}

.form-group {
    padding-top: 15px;
}

h2.page-title a {
    font-weight: 300;
    color: #1d1d1d;
}

.page-item {
    margin: 0 5px;
}

a.page-link {
    color: var(--primary--color);
    border-radius: 19px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 19px;
}

.page-item.active .page-link {
    background-color: var(--primary--color);
    border-color: var(--primary--color);
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

/* Component */
.navbar-travy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
    grid-gap: 10px;
    gap: 10px;
    height: 72px;
    background: #ffffff;
}

main {
    padding-top: 72px;
    padding-bottom: 72px;
}

footer {
    background-color: var(--background-component);
    padding: 60px 0;
}

footer .footer-copyright {
    font-size: 9px;
    line-height: 30px;
    /* identical to box height, or 333% */

    letter-spacing: 0.1px;

    color: #1d1d1d;
}

footer .logo-footer {
    margin-bottom: 50px;
}

section.banner {
    margin-bottom: 48px;
}

section.banner .loading {
    display: block;
    border-radius: 6px;
    aspect-ratio: 16/4;
    background-color: var(--stroke--color);
    animation: skeletonanimation 2s ease-in-out infinite;
}

section.product-list {
    padding-bottom: 48px;
}

section.product-list .title-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.product-box {
    margin-bottom: 30px;
    background: #ffffff;
    overflow: hidden;
    /* Box Shadow */

    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
}

.product-box .card-img {
    overflow: hidden;
    height: 200px;
}

.product-box .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-box .card-body {
    padding: 10px 12px;
    text-decoration: none !important;
}

.product-box .card-body .title {
    display: block;
    height: 38px;
    margin-bottom: 15px;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--black-color);
    /* identical to box height */
}

.product-box .card-body .price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 5px;
    gap: 5px;
}

.product-box .card-body .price .normal_price {
    display: block;
    min-height: 15px;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    /* identical to box height */

    -webkit-text-decoration-line: line-through;

            text-decoration-line: line-through;

    color: var(--text-secondary);
}

.product-box .card-body .price .price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--primary--color);
}

.shop-box .card-img {
    height: 172px;
}

.wifi-placeholder .card-img {
    background-color: var(--pill--hard--grey);
    animation: skeletonanimation 1s ease-in-out infinite;
}

.wifi-placeholder span.title,
.wifi-placeholder .card-body .price span,
.wifi-placeholder span.title {
    display: block;
    height: 14px;
    width: 100%;

    background-color: var(--pill--hard--grey);
    animation: skeletonanimation 1s ease-in-out infinite;
}

a.product-link:hover {
    text-decoration: none !important;
}

.card-shadow-box {
    display: block;
    padding: 35px 40px 30px;
    margin-bottom: 30px;
    background: #ffffff;
    /* Box Shop Shadow */

    box-shadow: var(--box--shadow);
    border-radius: 20px;
}

.card-shadow-box .content {
    margin-bottom: 20px;
}

.card-shadow-box .info-bank .bank-info {
    font-size: 10pt;
    margin-bottom: 0;
}

.card-shadow-box h6 {
    margin-bottom: 10px;
}

.card-shadow-box .description-info {
    font-size: 11pt;
}

.card-shadow-box .wifi-return-receive .title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 11pt;
    line-height: 16px;
}

.order-amount-box {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.order-amount-box .amount-detail-section .amount-detail {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
}

.order-amount-box .total-amount .amount-detail {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: var(--primary--color);
}

.vending-machine-form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    margin: 15px 0;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid var(--stroke--color);
}

.vending-machine-form .sub-title {
    font-style: normal;
    font-weight: 700;
    font-size: 11pt;
    line-height: 16px;
}

.vending-machine-form .info {
    font-style: normal;
    font-size: 11pt;
    line-height: 20px;
}

/* Shop */
.shop-detail-box {
    padding-top: 60px;
}

.shop-detail-box h2,
.shop-detail-box .price-section {
    margin-bottom: 30px;
}

.modem-description-info {
    white-space: pre-wrap;
}

.shop-description-text {
    white-space: pre-wrap;
}

.shop-detail-loading .img-placeholder {
    margin-bottom: 30px;
    aspect-ratio: 1/1;
    background-color: var(--stroke--color);
    animation: skeletonanimation 2s ease-in-out infinite;
}

.shop-detail-loading .placeholder-title,
.shop-detail-loading .placeholder-price {
    margin-bottom: 30px;
    display: block;
    height: 30px;
    background-color: var(--stroke--color);
    animation: skeletonanimation 2s ease-in-out infinite;
}

.shop-detail-loading .placeholder-title {
    height: 50px;
}

.shop-detail-loading .placeholder-price {
    width: 40%;
    height: 30px;
}

.select-variant-list {
    padding: 15px 0;
}

.price-secondary {
    font-style: normal;
    font-size: 15px;
    line-height: 33px;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    /* identical to box height */

    letter-spacing: 0.2px;

    /* Primary Color */

    color: var(--text-secondary);
}

.price-big {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    letter-spacing: 0.2px;

    /* Primary Color */

    color: var(--primary--color);
}

.select-variant-list .variant-label {
    display: block;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13pt;
    line-height: 19px;
}

.variant-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
}

.variant-item {
    outline: 0;
    min-width: 100px;
    height: 32px;
    border: none;
    border-radius: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    text-align: center;
    color: #1d1d1d;
    cursor: pointer;
}

.variant-item.active {
    background-color: var(--primary--color);
    color: #fff;
}

.variant-item:disabled {
    opacity: 0.7;
}

.quantity-input {
    padding: 15px 0;
    margin-bottom: 15px;
}

.quantity-input .qty-label {
    display: block;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13pt;
    line-height: 19px;
}

.qty-form-input {
    width: 120px;
    display: flex;
    height: 32px;
    border-radius: 20px;
    border: solid 1px #d5d5d5;
    background-color: #fff;

}

.qty-form-input button {
    width: 30px;
    background: 0 0;
    outline: 0;
    border: none;
    font-weight: 900;
    cursor: pointer;
}

.qty-form-input input {
    flex-grow: 1;
    width: 35px;
    text-align: center;
    border: none;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .18px;
    text-align: center;
    color: #1d1d1d;
}

.qty-form-input input:disabled {
    background-color: #ffffff;
}

/* Checkout Page */
.payment-method-list {
    /* margin: 0 -15px; */
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
}

.payment-method-button {
    height: 90px;
    min-width: 140px;
    border-radius: 10px;
    border: solid 1px #d5d5d5;
    background-color: #fff;
    outline: 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9;
    letter-spacing: .1px;
    text-align: center;
    color: #1d1d1d;
    transition: 0.2s;
}

.payment-method-button img {
    display: block;
    max-height: 42px;
    margin: 7px auto;
}

.payment-method-button:hover {
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.09);
}

.payment-method-button.active {
    border: 1px solid var(--primary--color);
    box-shadow: 0px 7px 23px rgba(247, 152, 36, 0.19);
}

.order-list {
    margin-bottom: 20px;
}

.order-list .order-label {
    display: block;
    font-size: 13px;
}

.order-list .order-label.title {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
}

.order-list .order-label.product-title {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 16px;
}

.order-divider {
    border: 1px dashed #D5D5D5;
    margin-bottom: 20px;
}

.order-payment-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-payment-title h4 {
    margin-bottom: 0;
}

.order-payment-title .order-number {
    padding: 5px 25px;
    background: #D6E8EE;
    border-radius: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2095BA;
}

/* Payment */
.checkout-loading .order-placeholder {
    display: block;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    background-color: var(--pill--hard--grey);
    animation: skeletonanimation 1s ease-in-out infinite;
}

.payment-bank-info {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* identical to box height, or 125% */

    letter-spacing: 0.18px;

    color: #000000;
}

.payment-bank-info .bank-thumb {
    padding-top: 10px;
}

.payment-bank-info .bank-thumb img {
    max-width: 150px;
}

.bank-form-data {
    padding: 10px 0;
}

.bank-form-data span {
    display: block;
}

.bank-form-data .label {
    margin-bottom: 10px;
}

.bank-form-data .value {
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
}

.bank-form-data .value-bank {
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
}

.payment-with-link .payment-thumb {
    width: 100px;
    margin-bottom: 10px;
}

.payment-with-link .payment-link-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    margin-bottom: 30px;
}

.payment-with-link .payment-link-amount {
    margin-bottom: 30px;
}

.payment-with-link .payment-link-amount .label {
    margin-bottom: 10px;
}

.payment-with-link .payment-link-amount .amount {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 25px;
}


.payment-qris .qris-payment-img {
    max-width: 400px;
    width: 100%;
}

.payment-qris .payment-link-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    margin-bottom: 30px;
}

.payment-qris .payment-link-amount {
    margin-bottom: 30px;
}

.payment-qris .payment-link-amount .label {
    margin-bottom: 10px;
}

.payment-qris .payment-link-amount .amount {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 25px;
}

.order-status-info {
    padding: 20px 0;
}

.order-status span {
    display: block;
}

.order-status .label {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
}

.order-status .value {
    font-style: normal;
    font-size: 18px;
    line-height: 16px;
}

.vending-machine-pickup {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: monospace;
    padding: 20px 0;
    grid-gap: 20px;
    gap: 20px;
}

.vending-machine-pickup .label-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
}

.vending-machine-pickup .label-pickup-number {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 18px;
}

.prompt-payment-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.prompt-payment-status .image-status {
    width: 250px;
    margin-bottom: 40px;
}

.prompt-payment-status h3 {
    margin-bottom: 10px;
}

.prompt-payment-status .sub-heading {
    margin-bottom: 40px;
}

@media (max-width: 575.98px) {
    .order-payment-title {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 10px;
        gap: 10px;
    }

    .payment-method-list {
        grid-gap: 10px;
        gap: 10px;
    }

    .payment-method-list .payment-method-button  {
        width: calc(50% - 5px);
        min-width: auto;
    }

    .btn-checkout-payment {
        width: 100%;
    }
}
